const PAYMENT_PAGE_CONSTANTS = {

    priceComparisonTableConfig: {
        title: 'Our recharge options are changing',
        columns: [
            {
                label: 'Current price',
                dataKey: 'currentPlan'
            },
            {
                label: 'New price from 22 October 2024',
                dataKey: 'newPlan'
            }
        ],
        tableData: {
            TPPCP: {
                label: 'Telstra Prepaid Mobile Plan',
                smallTitle: 'From 22 October 2024, our recharge prices are increasing.',
                data: [
                    {
                        currentPlan: '$12',
                        newPlan: '$13'
                    },
                    {
                        currentPlan: '$22',
                        newPlan: '$26'
                    },
                    {
                        currentPlan: '$35',
                        newPlan: '$39'
                    },
                    {
                        currentPlan: '$45',
                        newPlan: '$49'
                    },
                    {
                        currentPlan: '$55',
                        newPlan: '$59'
                    },
                    {
                        currentPlan: '$70',
                        newPlan: '$74'
                    },
                    {
                        currentPlan: '$230',
                        newPlan: 'Discontinued'
                    },
                    {
                        currentPlan: '$300',
                        newPlan: 'Price stays the same'
                    },
                    {
                        currentPlan: '$365',
                        newPlan: 'Price stays the same'
                    }
                ]
            }
        }
    }
};

export default PAYMENT_PAGE_CONSTANTS;
