import { validateFirstName, validateLastName, validateMiddleName, validateBirthDate } from "../../helper";

export const editFieldModalMeta = {
    firstName: {
        readerId: 'update-firstName-modal-heading',
        modalTitle: 'Enter given name',
        modalDesc: "By editing your given name, you won't be able to edit any other details.",
        input: {
            label: 'Given name',
            key: 'firstName',
            name: 'firstName',
            validate: validateFirstName
        }
    },
    familyName: {
        readerId: 'update-familyName-modal-heading',
        modalTitle: 'Enter family name',
        modalDesc: "By editing your family name, you won't be able to edit any other details.",
        input: {
            label: 'Family name',
            key: 'familyName',
            name: 'familyName',
            validate: validateLastName
        }
    },
    middleName: {
        readerId: 'update-middleName-modal-heading',
        modalTitle: 'Enter middle name',
        input: {
            label: 'Middle name',
            key: 'middleName',
            name: 'middleName',
            validate: validateMiddleName
        }
    },
    birthDate: {
        readerId: 'update-birthDate-modal-heading',
        modalTitle: 'Enter date of birth',
        modalDesc: "By editing your date of birth, you won't be able to edit any other details.",
        input: {
            label: 'Date of birth',
            key: 'birthDate',
            name: 'editDOB',
            validate: validateBirthDate
        }
    }
}