/* eslint-disable camelcase */
import { MessageSection, Spacing, TextStyle } from '@able/react';
import { Field, reduxForm } from 'redux-form';
import Parser from 'html-react-parser';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import './servicedetails.css';
import { Input } from '../../../components/';
import { checkSimPattern, isEmpty } from '../../../adapters/validations';
import { getUiState } from '../../../adapters/store-utils';
import {
    SERVER_ERROR,
    PACKAGE_NOT_AVAILABLE,
    SIM_IN_USE_ERROR,
    INVALID_SIM,
    SIM_PRE_CONDITION_NO_MATCH_ERROR,
    ORDER_IN_PROGRESS,
    SIM_IS_UNSOLD,
    SIM_ORDER_COMPLETED,
    SIM_ORDER_INPROGRESS,
    NETWORK_FAILURE
} from '../../../adapters/errorCode';
import { getSerialNumber, nextPage, getCurrentApplicationPath, submitFailure, focusOnElement, isOutageWindow, getBrandNameFromInvalidSimStatus, getAbleSpriteSheet } from '../../../adapters/utils';
import RadioBar from '../../../components/RadioBar';
import PPVModal from '../PPVModal';
import FullPageError from '../../../components/FullPageError/FullPageError';
import SpinningButton from '../../../components/SpinningButton/SpinningButton';
import WELCOME_LOCALE from '../../../assets/locale/v1/welcome';
import Auth from '../Auth/auth';
import { addDataLayerUser, addDataLayerEventInfo } from '../../../adapters/analytics-utils';
import { userProfileInfoMeta } from '../../../adapters/constants';
import GlobalBanner from '../../../common/components/globalBanner/globalBanner';
import { redirectToMyBoost } from '../../../adapters/esim-utils';

const ableSpriteSheet = getAbleSpriteSheet();

/**
 * Service details stateless component
 *
 * @param {any} { ...props }
 * @returns
 */
const ServiceDetails = ({ ...props }) => {
    const { loggedIn = false } = getUiState();
    const currentRouteName = getCurrentApplicationPath();
    const showCustomerRouteName = ['/welcome'];
    const showCustomer = showCustomerRouteName.indexOf(currentRouteName) !== -1 && loggedIn;
    // global state
    const {
        handleSubmit,
        actions: { resetErrors, triggerPortingModal },
        showPortingModal
    } = props;
    const {
        app: { hasErrorOccurred = false, loginCheckInProgress },
        appData: {
            uiState: { selectedActivationFlow, isEsimActivation = false },
            serviceNumber: { failedAttempts = 0 },
            isOdaFlow,
            serviceNumber = {}
        }
    } = props;
    const { PPA_OUTAGE_NOTIFICATION_DISPLAY = 'false', PPA_OUTAGE_NOTIFICATION_MESSAGE = '', PPA_OUTAGE_NOTIFICATION_START_TIME = '', PPA_OUTAGE_NOTIFICATION_END_TIME = '' } = window.appConfig || {};
    // state validations
    const {
        validations: { isValidatingNumber, isNumberChecked, isFetchingCustomerData, simIdIsSerialNumber = false }
    } = props;
    // errors
    const { errors } = props;

    const {
        formData: { selectedFlow, yourPhoneNumberLabel }
    } = props;
    let outageDisplay = false;

    if (PPA_OUTAGE_NOTIFICATION_DISPLAY === 'true') {
        outageDisplay = isOutageWindow(PPA_OUTAGE_NOTIFICATION_START_TIME, PPA_OUTAGE_NOTIFICATION_END_TIME);
    }
    // local validation
    const disableCheckButton = isFetchingCustomerData || outageDisplay;
    const disableSimIdField = isNumberChecked || disableCheckButton || outageDisplay;

    // const isProvidedNumberFlow = isDeviceTypeMobile && activationFlowFormValue === 'providedNumber';
    // const showNewNumber = selectedDevice && (selectedDevice === 'Telstra-Prepaid-Wireless-Broadband' || isProvidedNumberFlow);
    // const wrapperProps = { title: props.title, bannerImage: props.pageImage, altText: props.imageAltText, titleAltStart: props.titleAltStart, telstraAuthCustomerProfile };
    // const imageModalProps = { bannerImage: props.pageImage, altText: props.imageAltText };
    // In ODA flow, replace field with sanitized sim number
    if (isOdaFlow && isNumberChecked) {
        const { packageData: { simSerial = '' } = {} } = serviceNumber;
        const simNumber = getSerialNumber(simSerial);
        props.change('simNumber', simNumber);
    }
    const btnText = isNumberChecked ? 'Continue' : 'Check serial number';

    let errorProps = {
        errorMessage: 'We’re unable to process your request at this time. Please try again later. ',
        errorText: 'Something went wrong'
    };

    if (outageDisplay) {
        errorProps = {
            errorMessage: PPA_OUTAGE_NOTIFICATION_MESSAGE || '',
            errorText: 'Pre-Paid Activation Unavailable'
        };
    }

    const authProps = {
        outageDisplay,
        loginCheckInProgress
    };

    if (failedAttempts >= WELCOME_LOCALE.allowedAttempts) {
        errorProps = {
            context: WELCOME_LOCALE.errorContext.EXCEEDED_ATEMPTS,
            errorMessage: "You've run out of attempts to enter your SIM serial number.",
            errorText: 'Unable to process request'
        };
    }
    let priceRise = false;
    const {
        deviceData: { simVersion = '' }
    } = props;
    // Analytics data to add 'contact uuid' for logged in user
    const { app: { appData: { customerInfo = {}, address: { customerState } = {} } } = {} } = props;
    const { contactId = '' } = customerInfo;
    addDataLayerUser({ type: userProfileInfoMeta.USER_CONTACT_UUID, value: contactId, address: {
        stateProvince: customerState }
    });
    priceRise = simVersion === 'PRE_PRICE_RISE';
    const headerText = 'Let’s get started!';

    return (
        <div>
            <GlobalBanner onPage="landing" className="infobanner-landing" />
            {!hasErrorOccurred && !outageDisplay && (
                <div className={`row  ${isNumberChecked ? 'isNumberChecked' : ''}`}>
                    <div className="col-lg-5">
                        {!isEsimActivation && !loggedIn && <Auth {...authProps} />}
                        {showCustomer && !isEsimActivation && (
                            <div>
                                <TextStyle alias="HeadingC" element="h3" className="mb-2">
                                    Welcome back!
                                </TextStyle>
                            </div>
                        )}
                        <TextStyle tabIndex="-1" alias="HeadingA" element="h1" className="mbm">
                            {headerText}
                        </TextStyle>
                        {renderErrors(errors, props)}
                        {!isEsimActivation && (
                            <Fragment>
                                <TextStyle alias="TextBodyShort" element="p" className="mb-3">
                                    Enter your SIM serial number located on the back of your SIM pack.
                                </TextStyle>
                                <div className="mx-auto sim-kit-image-mobile">
                                    <img
                                        className="sim-kit-image img-fluid"
                                        alt=""
                                        /* eslint-disable global-require */
                                        src={require('../../../assets/img/Card-Visual-mobile.png')}
                                        /* eslint-enable global-require */
                                    />
                                </div>
                                <Field
                                    name="simNumber"
                                    id="simNumber"
                                    component={Input}
                                    type="text"
                                    label={'SIM serial number'}
                                    onChange={resetErrors}
                                    disabled={disableSimIdField}
                                    aria-required="true"
                                    maxLength="13"
                                    assistiveText="This is a 13-digit number."
                                />
                            </Fragment>
                        )}
                        {isNumberChecked && priceRiseMessage()}
                        {isNumberChecked && renderActivationFlowButtons(selectedFlow, simIdIsSerialNumber, yourPhoneNumberLabel, props)}
                        {selectedActivationFlow && selectedActivationFlow === 'portNumber' && renderServiceTransferInfoCard(props)}
                        {!isEsimActivation && !isNumberChecked && (
                            <SpinningButton
                                id="chkSimBtn"
                                onSubmit={handleSubmit(checkSimNumber)}
                                variant="HighEmphasis"
                                stylingClass="my-4"
                                isDisabled={isValidatingNumber || outageDisplay}
                                isLoading={isValidatingNumber}
                                aria-describedby="server-error-description"
                                buttonText={btnText}
                            />
                        )}
                        {(isEsimActivation || isNumberChecked) && (
                            <SpinningButton
                                id="chkSimBtn2"
                                onSubmit={handleSubmit(validateAndProceed)}
                                variant="HighEmphasis"
                                stylingClass="w-100 my-4"
                                isDisabled={disableCheckButton}
                                // hide={isNumberChecked || appLock}
                                isLoading={isFetchingCustomerData}
                                aria-describedby="server-error-description"
                                buttonText={btnText}
                            />
                        )}
                    </div>
                    {!isEsimActivation && (
                        <div className="col-lg-5 div-image mx-auto sim-banner">
                            <img
                                className="sim-kit-image img-fluid hide-in-mobile"
                                alt=""
                                /* eslint-disable global-require */
                                src={require('../../../assets/img/Card-Visual-desktop.png')}
                                /* eslint-enable global-require */
                            />
                        </div>
                    )}
                    {selectedActivationFlow && selectedActivationFlow === 'portNumber' && showPortingModal && <PPVModal {...props} />}
                </div>
            )}
            {(hasErrorOccurred || errors.numberErrorCode === NETWORK_FAILURE || outageDisplay) && (isEsimActivation ? redirectToMyBoost() : <FullPageError {...errorProps} />)}
        </div>
    );
};

const priceRiseMessage = () => {
    const titleText = 'Our recharge options are changing';
    const description = 'From 22 October 2024, our recharge prices are increasing, and some plan inclusions are changing.';
    const actionText = 'Learn more';
    const actionLink = 'https://news.boost.com.au/latest-plans';

    const newTabForRechargeInfo = () => {
        window.open(actionLink, '_blank');
    };

    return (
        <Spacing bottom="spacing2x" top="spacing2x">
            <MessageSection
                developmentUrl={ableSpriteSheet}
                variant="Information"
                description={description}
                titleText={titleText}
                titleElement="h2"
                actionId="recharge-info"
                actionCallback={newTabForRechargeInfo}
                actionElement="button"
                actionText={actionText}
            />
        </Spacing>
    );
};

const validateAndProceed = (values, dispatch, props) => {
    const {
        appData: {
            uiState: { selectedActivationFlow }
        },
        actions: { updateStepperCustomData, triggerPortingModal, resetPorting }
    } = props;

    if (!isEmpty(selectedActivationFlow) && selectedActivationFlow === 'providedNumber') {
        nextPage(updateStepperCustomData);
    } else if (selectedActivationFlow && selectedActivationFlow === 'portNumber') {
        resetPorting();
        triggerPortingModal(true);
    }
};

// LOCAL ACTIONS
/**
 * Sim field form validation
 *
 * @param {object} values form values
 * @param {object} props sim invalid error messages
 * @returns
 */
const validate = (values) => {
    const errors = {};

    if (isEmpty(values.simNumber)) {
        errors.simNumber = 'Required field. Please enter the 13-digit SIM serial number (numeric only).';
    } else if (values.simNumber && checkSimPattern(values.simNumber)) {
        errors.simNumber = 'Invalid input. Please enter a valid 13-digit serial number (numeric only).';
    }
    if (isEmpty(values.selectedActivationFlow)) {
        errors.selectedActivationFlow = 'Please select type of number you would like to use';
    }

    return errors;
};

/**
 * Form submit function
 *
 * @param {object} values form values
 * @param {function} dispatch dispactcher
 * @param {object} props props to get parent action on valid form submit
 */
const checkSimNumber = (values, dispatch, props) => {
    const { isNumberValid, resetErrors } = props.actions;
    resetErrors();
    isNumberValid(values.simNumber);
};

/**
 * Render radio buttons for selecting application flow
 * Option 1 is conditional display based on sim id type (mobile or serial number)
 *
 * @param {object} selectedFlow object with option text
 * @param {bool} simIdIsSerialNumber flag which checks the sim id type
 * @returns
 */
const renderActivationFlowButtons = (selectedFlow, simIdIsSerialNumber, yourPhoneNumberLabel, props) => {
    const { option1: serialNumberText, option2: transferNumberText } = selectedFlow;
    const {
        appData: {
            uiState: { selectedActivationFlow }
        },
        UpdateError,
        validationError
    } = props;
    validationError && addDataLayerEventInfo('error', 'inline', 'unavailable', 'Please select an option to continue.');
    return (
        <div className="form-group sim-details-radio-grp mt-3" role="radiogroup" aria-atomic="true" aria-live="polite" aria-label={yourPhoneNumberLabel} aria-required="true">
            <TextStyle alias="Label" className="radio-button-label mb-2" tabIndex="-1" id="radio-grp-title">
                Would you like to keep your existing number or get a new one?
            </TextStyle>
            <MessageSection className="my-3" developmentUrl={ableSpriteSheet} variant="Information" description={WELCOME_LOCALE.serviceDetails.numberCaptureMessage} />
            {validationError && (
                <div id="server-error-description" tabIndex="-1" className="mbxl">
                    <MessageSection developmentUrl={ableSpriteSheet} variant="Error" titleText="Required field." description="Please select an option to continue." />
                </div>
            )}
            <Field
                type="radio"
                id="providerNumber"
                isActive={selectedActivationFlow === 'providedNumber'}
                name="selectedActivationFlow"
                value="providedNumber"
                label={serialNumberText}
                component={RadioBar}
                UpdateError={UpdateError}
            />
            <Field
                type="radio"
                id="providerNumber"
                isActive={selectedActivationFlow === 'portNumber'}
                name="selectedActivationFlow"
                value="portNumber"
                label={transferNumberText}
                component={RadioBar}
                UpdateError={UpdateError}
            />
        </div>
    );
};

const renderServiceTransferInfoCard = (props) => {
    const {
        appData: {
            uiState: { isEsimActivation = false }
        },
        formData: {
            portingInfoCard: { label, esimLabel }
        }
    } = props;
    const infoLabel = isEsimActivation ? esimLabel : label;
    return (
        <div id="portNumber-description" className="mb-4 mt-3">
            <MessageSection developmentUrl={ableSpriteSheet} variant="Information" description={infoLabel} />
        </div>
    );
};

/**
 * Renders the errors returned from service call
 * Based on app state valiable
 *
 * @param {object} errors
 * @param {object} desc
 * @returns
 */
const renderErrors = (errors, props) => {
    if (errors.hasNumberError) {
        let errorHeading = '';
        let errorText = '';
        const {
            serviceNumber: { completionDate = '' }
        } = props.appData;

        switch (errors.numberErrorCode.code) {
            case SERVER_ERROR:
                errorText = "We’re experiencing technical difficulties. Please try again later or contact 24x7 Chat'";
                break;
            case PACKAGE_NOT_AVAILABLE:
                errorText =
                    "<p>This SIM serial you've entered is invalid, expired or already exists. Please check the number and try again.<br>Please note, your SIM can take up to 24hrs to activate. You'll be notified by email when your service is active. <br><br><strong>For $2 SIMS</strong><br> If you have an invalid or expired SIM, you can order a new one <a href='https://boost.com.au/products/2-starter-sim'> here </a> or request a refund from the place of purchase.</p>";
                break;
            case SIM_IN_USE_ERROR:
                errorHeading = 'This SIM has expired';
                errorText = 'Please try another SIM.';
                break;
            case ORDER_IN_PROGRESS:
                errorHeading = 'This serial number is already in use';
                errorText = "If you've just activated, it might need a few more minutes.";
                break;
            case SIM_ORDER_COMPLETED:
                errorHeading = 'SIM already active';
                errorText = `This SIM has already been activated on ${completionDate}. Check your email for details of that activation. For a new SIM activation, enter a different SIM serial number.`;
                break;
            case SIM_ORDER_INPROGRESS:
                errorHeading = 'SIM activation in progress';
                errorText = `We're already in the process of activating this SIM, submitted on ${completionDate}. Please wait, or for a new activation enter a different SIM serial number.`;
                break;
            case INVALID_SIM:
                let brandName = getBrandNameFromInvalidSimStatus(errors.numberErrorCode.status);
                let brandUrl = 'https://prepaid.activate.telstra.com.au';
                if (brandName === 'JB Hi-Fi') {
                    brandName = 'JB Hi-Fi Mobile';
                    brandUrl = 'https://jbhifi.com.au/jbmobile';
                }
                errorHeading = 'This is not a Boost SIM';
                errorText =
                    `This looks like a ${brandName} SIM card.<span class="breakWord"> <span>Please activate your service at</span> ` +
                    `<a class="m-0 p-0" target="_blank" href="${brandUrl}">${brandUrl}</a></span>`;
                break;
            case SIM_IS_UNSOLD:
                errorHeading = 'This SIM is not validated.';
                errorText = 'Please try again in 24 hours or return the SIM to place of purchase.';
                break;
            case SIM_PRE_CONDITION_NO_MATCH_ERROR:
            default:
                errorHeading = 'Something went wrong';
                errorText = "We're unable to activate this SIM at the moment.";
                break;
        }
        focusOnElement('#server-error-description');
        return <MessageSection className="mb-3" developmentUrl={ableSpriteSheet} variant="Error" titleText={Parser(errorHeading)} description={Parser(errorText)} />;
    }
    return false;
};

const fieldList = ['simNumber', 'selectedActivationFlow'];

// REDUX-FORM
const ServiceDetailsForm = reduxForm({ form: 'simValidationForm', validate, destroyOnUnmount: false, onSubmitFail: submitFailure(fieldList) })(ServiceDetails);

// Default values and persisting state
const ConnectedFormAndState = connect((state) => {
    const {
        uiState: { selectedDevice }
    } = state.app.appData;

    // activationFlowFormValue and uiState.selectedActivationFlow are same values
    // this is a one off work around when these two values goes out of sync when
    // selected device is not mobile phone.
    return { initialValues: { selectedDevice } };
})(ServiceDetailsForm);

export default ConnectedFormAndState;
