import React, { useEffect } from 'react';
import { TextField } from '@able/react';
import { getAbleSpriteSheet } from '../../../adapters/utils';
import { addDataLayerEventInfo } from '../../../adapters/analytics-utils';

const ableSpriteSheet = getAbleSpriteSheet();

export function AbleTextField({
    id,
    input,
    label,
    type,
    disabled,
    maxLength,
    helpText,
    inputHelper,
    className = '',
    "aria-required": ariaRequired,
    meta: {
        submitFailed,
        touched,
        error
    } = {}
}) {
    useEffect(() => {
        if (submitFailed && touched && error) {
            addDataLayerEventInfo('error', 'Inline', 'unavailable', error);
        }
    },[submitFailed, touched, error]);
    return (
        <TextField
            {...input}
            id={id}
            label={label}
            className={className}
            type={type}
            defaultValue=""
            disabled={disabled}
            maxLength={maxLength}
            helpText={helpText}
            invalidInputText={touched && error}
            invalid={!!(touched && error)}
            inputHelper={inputHelper}
            aria-required={ariaRequired}
            developmentUrl={ableSpriteSheet}
        />
    );
}