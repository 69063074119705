import { Spacing, TextStyle } from '@able/react';
import React from 'react';

export default function ReadOnlyField({
    label = '',
    value = '',
    ...props
}) {
    return (
        <Spacing bottom="spacing2x" {...props}>
            <TextStyle alias="Label">{label}</TextStyle>
            <TextStyle alias="TextBodyShort">{value}</TextStyle>
        </Spacing>
    );
}
